/*REACT*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*BASE*/
import * as _base from "../../../_base";
import ZChat from "../../_Utilities/ZChat/ZChat";
import ZReduxForm from "../../_Utilities/ZReduxForm/ZReduxForm";
import ZRestrict from "../../_Utilities/ZRestrict/ZRestrict";
import ZPopup from "../../_Utilities/ZPopup/ZPopup";
import ZDetectBrowser from "../../_Utilities/ZDetectBrowser/ZDetectBrowser";

import { v1 } from "uuid";
import axios from "axios";
import _ from "lodash";
import ReactJson from "react-json-view";
import Translate from "./_Parts/Translate";
import * as moment from "moment";

/*CSS*/
import "./Talk2Elain.css";
import "./Talk2ElainMS.css";
import "./hkpost.css";
import { DOMAIN } from "../../../_base/actions";

class Talk2Elain extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super();
    this.state = {
      sessionID: v1(),
      lang: props.match.params.lang || "tc",
      intents: [],
      records: [],
      context: {
        DEBUG: false,
      },
      reminderEnabled: false,
      showReminder: true,
      showDisclaimer: true,
      showMenu: false,
      showSurvey: false,
      inputPlaceholder: "",
      available: true,
      popup: {
        type: "",
        message: "",
        onConfirm: () => {},
        onCancel: () => {},
        allowClickOutside: true,
      },
      LongPollAwaiting: false,
      terminateLongPoll: false,
      base: {
        url: {
          LongPoll: "web/longPoll",
        },
      },
      Connected: false,
    };
  }

  // onMountSurvey = (callbacks) => {
  //     this.MountSurvey = callbacks;
  // };

  onMountBrowser = (callbacks) => {
    this.MountBrowser = callbacks;
  };

  onMountChat = (callbacks) => {
    this.MountChat = callbacks;
  };

  onMountPopup = (callbacks) => {
    this.MountPopup = callbacks;
  };

  onMountBrowser = (callbacks) => {
    this.MountBrowser = callbacks;
  };

  getBrowser = () => {
    return this.MountBrowser && this.MountBrowser.GetBrowser();
  };

  Alert = (msg) => {
    this.setState(
      {
        showSurvey: false,
      },
      () => {
        //this.MountSurvey.Alert(msg);
      }
    );
  };

  Loading = (callback) => {
    this.setState(
      (state, props) => ({
        popup: {
          ...state.popup,
          type: "loading",
          message: "",
          onConfirm: () => {},
          onCancel: () => {},
        },
      }),
      () => {
        this.MountPopup.open();
        if (callback) callback();
      }
    );
  };

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
      authority: (props.user && props.user.authority && props.user.authority.Talk2Elain) || [],
    }));

    this.setAllStates(
      this.setChatbot(() => {
        console.log("[>] Connect to " + this.state.url);
        this.monitorSessionTimeout();
        this.welcomeMessage();
      })
    );

    this.MountChat.Connecting(true);
    this.SetPlaceHolder();
    //this.LongPoll();
  }

  monitorSessionTimeout() {
    if (this.state.sessionTimer) {
      clearInterval(this.state.sessionTimer);
    }
    const timer = setInterval(() => {
      if (moment().diff(this.state.lastMessageTime) > this.state.sessionTimeout) {
        this.triggerSessionTimeout();
        clearInterval(timer);
      }
    }, this.state.sessionTimeoutCheckInterval);
    this.setState({ sessionTimer: timer });
  }

  triggerSessionTimeout() {
    this.setState(
      (state, props) => ({
        ...props,
        popup: {
          ...state.popup,
          type: "alert",
          message: Translate.sessionTimeoutText[state.lang],
          confirmText: Translate.sessionTimeoutConfirmText[state.lang],
          allowClickOutside: false,
          onConfirm: () => {
            window.location.reload();
          },
        },
        terminateLongPoll: true,
      }),
      () => {
        this.MountPopup.open();
      }
    );
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  BlockEntry = (unBlockTime) => {
    this.setState(
      {
        showSurvey: true,
        available: false,
      },
      () => {
        console.log(this.state.available);
        this.MountSurvey.Block(unBlockTime);

        if (this.UnblockTimeout) clearTimeout(this.UnblockTimeout);
        this.UnblockTimeout = setTimeout(() => {
          this.setState({
            available: true,
          });
        }, unBlockTime);
      }
    );
  };

  RestartChat = () => {
    this.setState({
      convID: v1(),
      records: [],
      messages: [],
      showMenu: false,
      showReminder: true,
      showDisclaimer: true,
    });
  };

  setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  setChatbot = (callback) => {
    console.log("[-] START CHATBOT");
    let { interval, sessionTimeout, sessionTimeoutCheckInterval } = _base.config.chatbot;

    this.setState(
      {
        convID: v1(),
        livechat: false,
        url: DOMAIN + "web",
        requestTimeout: interval,
        lastMessageTime: moment(),
        sessionTimeout,
        sessionTimeoutCheckInterval,
        sending: false,
      },
      callback
    );
  };

  welcomeMessage = async () => {
    console.log("[-] welcomeMessage");

    let { url, lang, sessionID } = this.state;

    let input = {
      type: "text",
      content: "init",
    };
    const wakeupPayload = {
      session_id: sessionID,
      input: input,
      timestamp: new Date(),
    };
    let res = await axios.post(url, wakeupPayload);

    await this.LongPoll();
  };

  SetPlaceHolder = (mode) => {
    let { lang } = this.state;

    let placeholder = { tc: "", en: "" };
    let { inputField, connecting } = Translate;
    switch (mode) {
      case 0:
        placeholder = inputField.welcomePlaceholder;
        break;
      case 1:
        placeholder = inputField.buttonPlaceholderTC;
        break;
      default:
        placeholder = inputField.textPlaceholder;
        break;
    }

    this.setState({
      inputPlaceholder: placeholder[lang],
      connectReminder: connecting[lang],
    });
  };

  //#region Idle

  DocTitleBlink = (start) => {
    let title = "Live Chat Assistant";
    let newmsg = "New Message!!";
    if (start) {
      this.DocTitleBlinkHandler = setInterval(() => {
        document.title = document.title === title ? newmsg : title;
      }, 1000);
    } else {
      if (this.DocTitleBlinkHandler) {
        clearInterval(this.DocTitleBlinkHandler);
      }
      document.title = title;
    }
  };

  //#endregion Idle

  TriggerLiveAgent = () => {
    let { remoteConfig } = this.state;
    if (!remoteConfig) return;
    this.setState(
      {
        showSurvey: true,
      },
      () => {
        this.MountSurvey.TriggerLiveAgent();
      }
    );
  };

  onSend = (input, id) => {
    let { LongPollAwaiting } = this.state;
    let inputO = {
      type: "text",
      content: input.text,
    };
    //this.MountChat.Typing();
    this.setState({ lastMessageTime: moment() });
    this.sendToServer(inputO, id, true);
    if (!LongPollAwaiting) this.LongPoll();
  };

  onQuickReply = (quickReply, _id) => {
    let input = {
      type: "button",
      content: quickReply.payload,
    };
    //this.MountChat.Typing();
    this.sendToServer(input, _id, true);
  };

  sendToServer = async (input, _id, record = false) => {
    let { url, sessionID, channel, remarks, user, selectedEnv } = this.state;
    let startTime = new Date();

    let payload = {
      JWT: user.JWT,
      env: selectedEnv,
      data: {
        session_id: sessionID,
        channel: channel,
        input: input,
        timestamp: new Date(),
        remarks: remarks,
      },
    };

    console.log("[>] Data Sent: " + startTime, payload);

    try {
      let res = await axios.post(url, payload.data);
      // this.MountChat.Typing(true);
      this.MountChat.SetStatus(_id, "received");

      if (res.data.Success === true) {
        let endTime = new Date();
        //if (_id) this.MountChat.SetStatus(_id, "read");

        // let processTime = (endTime - startTime) / 1000;
        // console.log("[<] Data Received: " + endTime);
        // console.log("[-] Process Time: " + processTime + "s");
        // console.log("[-] MW Response: ", res.data);

        // // if (record) this.recordForInfo(payload.data, res.data.payload, startTime, endTime, _id);

        // // if (res.data.payload.live_chat) this.startLiveChatPoll();
        // // else this.stopLiveChatPoll();
        // let msg = res.data.message;
        // msg.quickReplies = this.ParseButton(res.data.message);

        // this.MountChat.Append({
        //   _id: v1(),
        //   createdAt: new Date(),
        //   user: _base.config.chatbot.user,
        //   msg,
        // });
      } else {
        _base.func.handleError(this.props, res.data, (e) => {
          this.Alert(e);
        });
      }
    } catch (e) {
      this.MountChat.Typing(false);
      _base.func.handleError(this.props, e, (e) => {
        this.Alert(e);
      });
      if (_id) this.MountChat.SetStatus(_id, "pending");
    }
  };

  appendReturnMessage = (messages, _id) => {
    console.log(messages);
    this.MountChat.Append(messages.reverse());
    this.MountChat.SetStatus(_id, "read");

    let needForceRefresh = false;
    _.map(messages, (o, i) => {
      if (o.msg.table) needForceRefresh = true;
    });

    if (needForceRefresh) {
      setTimeout(() => {
        this.MountChat.ScrollToBottom();
      }, 500);
    }
  };

  Language = (input) => {
    let lang = "en";
    if (/\S?[\u4E00-\u9FFF\uF900-\uFAFF]\S?/i.test(input)) {
      lang = "tc";
    } else {
      lang = "en";
    }
    return lang;
  };

  onMsgPress = (messageId) => {
    console.log("onMsgPress", messageId);
  };

  onMsgLongPress = (messageId) => {
    console.log("onMsgLongPress", messageId);
  };

  renderForm = () => {
    let { form } = this.state;
    return (
      <ZReduxForm
        cssPrefix="t2einfo "
        form={form.id}
        title={form.title}
        schema={form.schema}
        onSubmit={form.onSubmit}
        onCancel={form.onCancel}
        defaultValue={form.editing}
        buttons={form.buttons}
        readOnly={form.readOnly}
      />
    );
  };

  renderOutput = () => {
    return (
      <div className="zbjsonviewer">
        <ReactJson name={false} enableClipboard={true} src={this.state.form.editing.output} />
      </div>
    );
  };

  toPage = (page) => {
    this.setState({
      page: page,
    });
  };

  renderPageContent = (content, page) => {
    return <div className={"t2e-content" + this.getBrowser() + (this.state.page === page ? "" : " hidden")}>{content}</div>;
  };

  renderPageButton = (label, page, restricted = []) => {
    let { authority } = this.props.user;
    if (!authority || !authority.Talk2Elain) return;
    return (
      <ZRestrict auth={Object.keys(authority.Talk2Elain)} restricted={restricted} key={label}>
        <div className={"t2e-pages-btn" + this.getBrowser() + (this.state.page === page ? " current" : "")} onClick={() => this.toPage(page)}>
          {label}
        </div>
      </ZRestrict>
    );
  };

  ToggleLang = () => {
    this.setState(
      (state, prop) => ({
        lang: state.lang === "en" ? "tc" : "en",
      }),
      () => {
        this.SetPlaceHolder();
      }
    );
  };

  Leave = () => {
    let { remoteConfig } = this.state;
    if (!remoteConfig) return;
    this.openSurvey(0);
  };

  onLeaveRedirect = (timeout = 10000) => {
    let { lang } = this.state;
  };

  ParseButton = (msg) => {
    try {
      let textMsg = msg.text;
      if (!textMsg) return msg;

      let textMsgSplit = textMsg.split("<br>");
      const re = new RegExp("^[0-9][.]");

      let buttons = [];
      _.map(textMsgSplit, (o) => {
        let res = re.exec(o);
        if (!res) return;
        // [1] English -->[1]
        let value = res[0];

        // [1] English --> English
        let text = res["input"].replace(value, "");
        let paylaod = res[0].replace("[", "").replace("]", "");
        buttons.push({
          type: "text",
          title: res[0] + text,
          payload: paylaod,
        });
      });

      return buttons;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  sendFirstMessage = async () => {
    let { lang } = this.state;
    let toSend = "hi";
    if (lang == "tc") toSend = "1";
    if (lang == "en") toSend = "2";
    if (lang == "sc") toSend = "3";
    let input = {
      type: "text",
      content: toSend,
    };
    await this.sendToServer(input, null, true);
    this.setState({ Connected: true });
  };

  LongPoll = async () => {
    try {
      let { base, sessionID, LongPollAwaiting, terminateLongPoll, Connected } = this.state;

      if (terminateLongPoll) {
        this.setState({
          LongPollAwaiting: false,
          terminateLongPoll: false,
        });
        return;
      }

      if (LongPollAwaiting) return await this.LongPoll();
      this.setState({ LongPollAwaiting: true });

      console.log("[-] LogPoll Started");
      let res = await axios.post(
        DOMAIN + base.url.LongPoll,
        {
          session_id: sessionID,
        },
        {
          requestTimeout: interval,
        }
      );
      this.MountChat.Typing(false);

      if (res && res.data && res.data.Success === true) {
        let { data } = res;

        if (data.lang) {
          this.setState({ lang: data.lang.toLowerCase() }); // adapt for inconsistency of lang names between UI and relay-server
        }

        if (data && data.Status && data.Status == "SessionEnd") {
          this.setState({ LongPollAwaiting: false });
          return;
        }

        if (!Connected) {
          await this.sendFirstMessage();
        } else {
          this.MountChat.Connecting(false);
          //show in cb
          if (this.MountChat) {
            let msg = res.data.message;
            this.MountChat.SetStatus(sessionID, "read");

            msg.quickReplies = this.ParseButton(msg);
            this.MountChat.Append({
              _id: v1(),
              createdAt: new Date(),
              user: _base.config.chatbot.user,
              msg,
            });
          }
        }
      }

      this.setState({ LongPollAwaiting: false });
      let { interval } = _base.config.chatbot;
      await this.LongPoll();
    } catch (e) {
      //supresss message for timout error
      // console.log(e.response.status);
      // console.log(e.response && e.response.status && e.response.status == 408);
      if (e.response && e.response.status && e.response.status != 408) console.log(e);
      this.setState({ LongPollAwaiting: false });
      await this.LongPoll();
    }
  };

  renderHeadLine() {
    let { lang } = this.state;
    let browser = this.getBrowser();
    return (
      <div className={"t2e-headline " + browser}>
        <div className={"t2e-headline-left " + browser}>
          <div className={"t2e-headline-icon logo " + browser}></div>
          <div className="t2e-headline-text">{Translate.virturalAssistant[lang]}</div>
        </div>
      </div>
    );
  }

  renderBullet() {
    return (
      <div className="t2e-reminder-bullet">
        <img src="Icon/bullet.png" alt="" draggable={false} />
      </div>
    );
  }

  renderSendBtn = () => {
    return <img src="Icon/send.png" alt="" />;
  };

  renderChat = () => {
    let browser = this.getBrowser();
    const { inputPlaceholder, connectReminder } = this.state;
    return (
      <div className={"t2e-chat " + browser}>
        <ZChat
          cssPrefix={"HKPost " + browser}
          onMounted={this.onMountChat}
          onSend={this.onSend}
          onQuickReply={this.onQuickReply}
          onMsgLongPress={this.onMsgLongPress}
          onMsgPress={this.onMsgLongPress}
          user={{
            _id: 1,
          }}
          sendBtn={this.renderSendBtn}
          longAnswerLength={500}
          showStatus={true}
          animated={true}
          showQuickRepliesAsButtons={false}
          showLapseTime={true}
          browser={browser}
          inputPlaceHolder={inputPlaceholder}
          connectReminder={connectReminder}
        />
      </div>
    );
  };

  render() {
    let { popup } = this.state;
    let browser = this.getBrowser();
    return (
      <div className={"Talk2Elain " + browser}>
        <ZDetectBrowser onMounted={this.onMountBrowser} />
        <ZPopup
          onMounted={this.onMountPopup}
          cssPrefix={"HKPost"}
          mode={popup.type}
          message={popup.message}
          onConfirm={popup.onConfirm}
          onCancel={popup.onCancel}
          allowClickOutside={popup.allowClickOutside}
          confirmText={popup.confirmText}
        />
        <div className={"Talk2Elain-inner " + browser}>
          {this.renderHeadLine()}
          {this.renderChat()}
        </div>
      </div>
    );
  }
}

export default connect(_base.func.mapStateToProps, _base.actions)(Talk2Elain);
